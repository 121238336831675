// EnglishBook.jsx
import {useEffect, useState} from 'react';
import BookPage from '../components/BookPage';
import SideMenu from '../components/SideMenu';
import '../styles/englishBook.css';

const EnglishBook = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [resourcesLoaded, setResourcesLoaded] = useState({});

  const bookPages = [
    { id: 1, title: "01 坐汽车" },
    { id: 2, title: "02 小区散步" },
    { id: 3, title: "03 安全用电" },
    { id: 4, title: "04 玩乐高" },
    { id: 5, title: "05 吹空调" },
    { id: 6, title: "06 煮米饭" },
    { id: 7, title: "07 起床" },
    { id: 8, title: "08 逛超市购物" },
    { id: 9, title: "09 穿衣服" },
    { id: 10, title: "10 吃药" },
    { id: 11, title: "11 天空" },
    { id: 12, title: "12 吃饺子" },
  ];

  // 预加载资源的函数
  const preloadResources = async (pageNum) => {
    if (resourcesLoaded[pageNum]) return;

    const page = bookPages.find(p => p.id === pageNum);
    if (!page) return;

    try {
      // 预加载图片
      const imgPromise = new Promise((resolve, reject) => {
        const img = new Image();
        img.src = `/112/${String(pageNum).padStart(2, '0')}${page.title.slice(2)}/图.png`;
        img.onload = () => resolve();
        img.onerror = reject;
      });

      // 预加载音频
      const audioPromise = new Promise((resolve, reject) => {
        const audio = new Audio();
        audio.src = `/112/${String(pageNum).padStart(2, '0')}${page.title.slice(2)}/音频.MP3`;
        audio.preload = 'auto';
        audio.oncanplaythrough = () => resolve();
        audio.onerror = reject;
      });

      await Promise.all([imgPromise, audioPromise]);
      setResourcesLoaded(prev => ({...prev, [pageNum]: true}));
    } catch (error) {
      console.error(`预加载页面 ${pageNum} 资源失败:`, error);
    }
  };

  // 初始加载当前页面和相邻页面的资源
  useEffect(() => {
    const preloadCurrentAndAdjacent = async () => {
      // 预加载当前页面
      await preloadResources(currentPage);

      // 预加载前一页和后一页
      if (currentPage > 1) await preloadResources(currentPage - 1);
      if (currentPage < bookPages.length) await preloadResources(currentPage + 1);
    };

    preloadCurrentAndAdjacent();
  }, [currentPage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu && !event.target.closest('.side-menu') && !event.target.closest('.menu-toggle')) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showMenu]);

  return (
    <div className="book-layout">
      <div className={`side-menu ${showMenu ? 'show' : ''}`}>
        <SideMenu
          pages={bookPages}
          currentPage={currentPage}
          onPageSelect={(pageId) => {
            setCurrentPage(pageId);
            setShowMenu(false);
          }}
        />
      </div>

      <div className="book-content">
        <button
          className="menu-toggle"
          onClick={() => setShowMenu(!showMenu)}
        >
          {showMenu ? '' : '>'}
        </button>
        <BookPage
          pageNumber={currentPage}
          isLoaded={resourcesLoaded[currentPage]}
        />
      </div>
    </div>
  );
};

export default EnglishBook;