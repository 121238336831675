// src/components/SideMenu.jsx
const SideMenu = ({ pages, currentPage, onPageSelect }) => {
  return (
    <div className="side-menu-content">
      <h2 className="book-title">英语启蒙从零打卡</h2>
      <div className="page-list">
        {pages.map(page => (
          <div
            key={page.id}
            className={`page-item ${currentPage === page.id ? 'active' : ''}`}
            onClick={() => onPageSelect(page.id)}
          >
            {page.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideMenu;