// src/components/BookPage.jsx
import { useEffect, useRef, useState } from 'react';
import './BookPage.css'; // 确保创建这个 CSS 文件




const BookPage = ({ pageNumber }) => {
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const audioRef = useRef(null);
  const [scale, setScale] = useState(1);

  const [imageLoaded, setImageLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const timeoutRef = useRef(null);
  const fadeIntervalRef = useRef(null);


  const [activeArea, setActiveArea] = useState(null);
  const [handPosition, setHandPosition] = useState({ x: 0, y: 0 });
  const [showHand, setShowHand] = useState(false);
  const [ripples, setRipples] = useState([]);

  // 创建点击波纹效果

// 修改 createRipple 函数，使其更准确
const createRipple = (x, y) => {
  // 确保坐标不为负数
  const safeX = Math.max(0, x);
  const safeY = Math.max(0, y);

  const newRipple = {
    id: Date.now(),
    x: safeX,
    y: safeY
  };

  setRipples(prev => [...prev, newRipple]);

  setTimeout(() => {
    setRipples(prev => prev.filter(ripple => ripple.id !== newRipple.id));
  }, 600);
};

  // 页面数据
  const pageData = {
    1: {
      image: "/112/01坐汽车/图.png",
      audio: "/112/01坐汽车/音频.MP3",
      areas: [
        { title: "安全带", coords: "304,392,525,513", audioStart: 4.5, audioDuration: 6 },
        { title: "安全座椅", coords: "752,400,959,519", audioStart: 10.8, audioDuration: 6 },
        { title: "晕车的", coords: "242,855,443,959", audioStart: 17.2, audioDuration: 6.6 },
        { title: "隧道", coords: "831,789,1023,903", audioStart: 24, audioDuration: 6 },
        { title: "窗户", coords: "234,1302,456,1403", audioStart: 30.2, audioDuration: 5 },
        { title: "后尾箱", coords: "799,1232,1005,1345", audioStart: 35.2, audioDuration: 6 },
        { title: "加油站", coords: "527,1267,766,1390", audioStart: 41.3, audioDuration: 7 },
      ],
      introductionTime: 4.3, // 自定义介绍时长
    },

    2: {
      image: "/112/02小区散步/图.png",
      audio: "/112/02小区散步/音频.MP3",
      areas: [
        { title: "散步", coords: "305,396,526,508", audioStart: 4.5, audioDuration: 5 },
        { title: "水坑", coords: "750,401,960,520", audioStart: 9.8, audioDuration: 5 },
        { title: "挥手", coords: "252,841,453,948", audioStart: 15, audioDuration: 4.5 },
        { title: "井盖", coords: "827,780,1045,893", audioStart: 19.6, audioDuration: 5 },
        { title: "树", coords: "231,1298,457,1405", audioStart: 25.5, audioDuration: 5 },
        { title: "长椅", coords: "788,1230,995,1345", audioStart: 30.8, audioDuration: 5 },
        { title: "自行车", coords: "526,1267,768,1394", audioStart: 36.1, audioDuration: 5.7 },
      ],
      introductionTime: 4.5, // 自定义介绍时长
    },

    3: {
      image: "/112/03安全用电/图.png",
      audio: "/112/03安全用电/音频.MP3",
      areas: [
        { title: "插座", coords: "305,396,524,509", audioStart: 4.5, audioDuration: 5 },
        { title: "金属物品", coords: "750,399,960,521", audioStart: 9.7, audioDuration: 6 },
        { title: "插头", coords: "252,842,455,949", audioStart: 15.8, audioDuration: 5.6 },
        { title: "电线", coords: "825,778,1044,892", audioStart: 21.3, audioDuration: 5 },
        { title: "火花", coords: "240,1341,467,1465", audioStart: 26.6, audioDuration: 6 },
        { title: "吹风机", coords: "788,1229,994,1345", audioStart: 33, audioDuration: 6 },
        { title: "触电", coords: "525,1266,766,1394", audioStart: 39.1, audioDuration: 8.3 },
      ],
      introductionTime: 4.5, // 自定义介绍时长
    },

// 在 BookPage.jsx 中的 pageData 对象中添加以下内容：

4: {
  image: "/112/04玩乐高/图.png",
  audio: "/112/04玩乐高/音频.MP3",
  areas: [
    { title: "乐高", coords: "309,397,521,506", audioStart: 4.4, audioDuration: 4.8 },
    { title: "说明书", coords: "758,407,952,514", audioStart: 9.4, audioDuration: 5.8 },
    { title: "红色积木", coords: "258,831,448,928", audioStart: 15.6, audioDuration: 5.2 },
    { title: "啪的一声拼合", coords: "832,785,1039,885", audioStart: 20.7, audioDuration: 4.4 },
    { title: "乐高人", coords: "245,1346,461,1460", audioStart: 25.2, audioDuration: 6.2},
    { title: "清理", coords: "529,1272,761,1390", audioStart: 36, audioDuration: 7.5 },
    { title: "数数", coords: "795,1235,987,1339", audioStart: 31.3, audioDuration: 4.5 },
  ],
  introductionTime: 4.3,
},

5: {
  image: "/112/05吹空调/图.png",
  audio: "/112/05吹空调/音频.MP3",
  areas: [
    { title: "空调", coords: "313,401,517,503", audioStart: 4.5, audioDuration: 7.5 },
    { title: "调节", coords: "756,407,953,517", audioStart: 12, audioDuration: 5.5 },
    { title: "窗户", coords: "258,832,449,928", audioStart: 17.7, audioDuration: 4.9 },
    { title: "毯子", coords: "832,782,1039,886", audioStart: 22.7, audioDuration: 5.2 },
    { title: "补充水分", coords: "247,1346,461,1460", audioStart: 28, audioDuration: 6 },
    { title: "遥控器", coords: "794,1237,988,1339", audioStart: 34, audioDuration: 7 },
    { title: "关闭", coords: "530,1271,761,1391", audioStart: 41.2, audioDuration: 6 },
  ],
  introductionTime: 4.5,
},

6: {
  image: "/112/06煮米饭/图.png",
  audio: "/112/06煮米饭/音频.MP3",
  areas: [
    { title: "量", coords: "311,403,518,504", audioStart: 4.5, audioDuration: 4.5 },
    { title: "冲洗", coords: "755,406,955,517", audioStart: 9, audioDuration: 5.5 },
    { title: "电饭锅", coords: "259,830,449,927", audioStart: 14.6, audioDuration: 5},
    { title: "水", coords: "831,785,1043,887", audioStart: 19.5, audioDuration: 5 },
    { title: "煮饭键", coords: "245,1348,460,1458", audioStart: 24.5, audioDuration: 6 },
    { title: "保温模式", coords: "793,1237,989,1338", audioStart:30.7, audioDuration: 6.8 },
    { title: "蒸汽", coords: "528,1270,761,1390", audioStart: 37.5, audioDuration: 7.8 },
  ],
  introductionTime: 4.5,
},

7: {
  image: "/112/07起床/图.png",
  audio: "/112/07起床/音频.MP3",
  areas: [
    { title: "起床", coords: "313,400,518,503", audioStart: 4.5, audioDuration: 3.8 },
    { title: "挠痒痒", coords: "756,406,953,516", audioStart: 8.3, audioDuration: 3.7 },
    { title: "伸展", coords: "257,832,449,927", audioStart: 12, audioDuration: 4.3 },
    { title: "窗帘", coords: "830,783,1041,886", audioStart: 16.3, audioDuration: 4.6 },
    { title: "闹钟", coords: "246,1345,460,1461", audioStart: 21, audioDuration: 4.7 },
    { title: "刷牙", coords: "794,1236,989,1340", audioStart: 25.8, audioDuration: 5.6 },
    { title: "早餐", coords: "529,1270,763,1389", audioStart: 31.4, audioDuration: 6 },
  ],
  introductionTime: 4.5,
},

8: {
  image: "/112/08逛超市购物/图.png",
  audio: "/112/08逛超市购物/音频.MP3",
  areas: [
    { title: "购物清单", coords: "311,403,518,504", audioStart: 4.7, audioDuration: 4.6 },
    { title: "称重", coords: "755,407,955,516", audioStart: 9.3, audioDuration: 7.2 },
    { title: "购物车", coords: "256,834,450,928", audioStart: 16.5, audioDuration: 5.6 },
    { title: "保质期", coords: "831,784,1039,886", audioStart: 22.2, audioDuration: 6.7 },
    { title: "选择", coords: "247,1347,462,1460", audioStart: 28.9, audioDuration: 4.5 },
    { title: "收银台", coords: "793,1235,989,1339", audioStart: 33.4, audioDuration: 7.1 },
    { title: "条形码", coords: "530,1270,762,1390", audioStart: 40.5, audioDuration: 6.5 },
  ],
  introductionTime: 4.6,
},

9: {
  image: "/112/09穿衣服/图.png",
  audio: "/112/09穿衣服/音频.MP3",
  areas: [
    { title: "挑选", coords: "312,402,518,504", audioStart: 4.6, audioDuration: 5.4 },
    { title: "提起", coords: "756,405,953,517", audioStart: 10, audioDuration: 5.7 },
    { title: "袖子", coords: "258,830,449,928", audioStart: 15.7, audioDuration: 5.4 },
    { title: "拉上拉链", coords: "831,786,1041,888", audioStart: 21.1, audioDuration: 5.6 },
    { title: "领子", coords: "245,1344,462,1461", audioStart: 26.7, audioDuration: 6 },
    { title: "扣子", coords: "794,1236,989,1340", audioStart: 32.7, audioDuration: 5 },
    { title: "帽子", coords: "530,1271,760,1390", audioStart: 37.7, audioDuration: 6 },
  ],
  introductionTime: 4.5,
},

10: {
  image: "/112/10吃药/图.png",
  audio: "/112/10吃药/音频.MP3",
  areas: [
    { title: "药物", coords: "309,402,518,505", audioStart: 4.8, audioDuration: 6 },
    { title: "感觉好些", coords: "756,406,953,515", audioStart: 10.8, audioDuration: 5.8 },
    { title: "水", coords: "258,833,449,929", audioStart: 16.6, audioDuration: 6 },
    { title: "嘴", coords: "830,784,1022,887", audioStart: 22.6, audioDuration: 5.5 },
    { title: "贴纸", coords: "254,1340,471,1453", audioStart: 28.1, audioDuration: 5.5 },
    { title: "准时", coords: "792,1236,989,1337", audioStart: 33.6, audioDuration: 6 },
    { title: "细菌", coords: "529,1269,762,1390", audioStart: 39.6, audioDuration: 7 },
  ],
  introductionTime: 4.8,
},

11: {
  image: "/112/11天空/图.png",
  audio: "/112/11天空/音频.MP3",
  areas: [
    { title: "太阳", coords: "312,400,518,505", audioStart: 4.5, audioDuration: 5.9 },
    { title: "星星", coords: "755,405,953,515", audioStart: 10.7, audioDuration: 5 },
    { title: "云", coords: "259,832,449,932", audioStart: 15.7, audioDuration: 6 },
    { title: "彩虹", coords: "830,782,1019,886", audioStart: 21.7, audioDuration: 5.3 },
    { title: "雪花", coords: "253,1340,472,1454", audioStart: 27, audioDuration: 5.8 },
    { title: "雨", coords: "795,1236,991,1341", audioStart: 32.8, audioDuration: 5 },
    { title: "月亮", coords: "528,1269,760,1390", audioStart: 37.8, audioDuration: 6 },
  ],
  introductionTime: 4.5,
},

12: {
  image: "/112/12吃饺子/图.png",
  audio: "/112/12吃饺子/音频.MP3",
  areas: [
    { title: "饺子", coords: "311,400,519,504", audioStart: 4.7, audioDuration: 6.8 },
    { title: "多少", coords: "755,404,953,515", audioStart: 11.5, audioDuration: 6.2 },
    { title: "装满", coords: "259,830,448,929", audioStart: 17.7, audioDuration: 6.6 },
    { title: "漂浮", coords: "831,784,1020,886", audioStart: 24.2, audioDuration: 6 },
    { title: "醋", coords: "257,1341,471,1451", audioStart: 30.2, audioDuration: 6.2 },
    { title: "冷冻", coords: "794,1238,990,1337", audioStart: 36.4, audioDuration: 6 },
    { title: "蒸", coords: "530,1270,761,1390", audioStart: 42.4, audioDuration: 6 },
  ],
  introductionTime: 4.7,
},
  };




  // 清理函数
  const cleanup = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (fadeIntervalRef.current) {
      clearInterval(fadeIntervalRef.current);
    }
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      audio.volume = 1;
    }
    setIsPlaying(false);
  };

  // 修改playAudio函数
  const playAudio = async (startTime, duration, fadeIn = false) => {
    const audio = audioRef.current;
    if (!audio) return;

    try {
      cleanup();

      audio.currentTime = startTime;
      audio.volume = fadeIn ? 0.1 : 1;

      await audio.play();
      setIsPlaying(true);

      return new Promise((resolve) => {
        timeoutRef.current = setTimeout(() => {
          cleanup();
          resolve();
        }, duration * 1000);
      });
    } catch (error) {
      console.error('音频播放失败:', error);
      cleanup();
    }
  };

  // 修改页面切换时的自动播放逻辑
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio || !pageData[pageNumber]) return;

    const introTime = pageData[pageNumber].introductionTime;

    const handleAutoPlay = async () => {
      try {
        await playAudio(0, introTime);
      } catch (error) {
        console.error('自动播放失败:', error);
      }
    };

    // 确保在组件挂载和页面切换时都会触发
    const timeoutId = setTimeout(() => {
      handleAutoPlay();
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      cleanup();
    };
  }, [pageNumber]);




  // 处理缩放
  useEffect(() => {
    const updateScale = () => {
      if (imageRef.current && containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const imageNaturalWidth = imageRef.current.naturalWidth;
        setScale(containerWidth / imageNaturalWidth);
      }
    };

    window.addEventListener('resize', updateScale);
    if (imageLoaded) {
      updateScale();
    }

    return () => window.removeEventListener('resize', updateScale);
  }, [imageLoaded]);

  const getScaledCoords = (coords) => {
    return coords.split(',').map(coord => Math.round(parseInt(coord) * scale)).join(',');
  };

  if (!pageData[pageNumber]) return null;
// 修改 handleAreaClick 函数和相关事件处理
const handleAreaClick = (area, event) => {
  let x, y;

  if (event.type === 'touchstart') {
    // 处理触摸事件
    const touch = event.touches[0];
    const rect = event.target.getBoundingClientRect();
    x = touch.clientX - rect.left;
    y = touch.clientY - rect.top;
  } else {
    // 处理鼠标点击事件
    const rect = event.target.getBoundingClientRect();
    x = event.clientX - rect.left;
    y = event.clientY - rect.top;
  }

  createRipple(x, y);
  setActiveArea(area);
  setShowHand(true);

  const coords = area.coords.split(',').map(Number);
  const centerX = (coords[0] + coords[2]) / 2 * scale;
  const centerY = (coords[1] + coords[3]) / 2 * scale - 60;
  setHandPosition({ x: centerX, y: centerY });

  playAudio(area.audioStart, area.audioDuration).then(() => {
    setActiveArea(null);
    setShowHand(false);
  });
};


  return (
    <div className="book-page" ref={containerRef}>
      <div className="image-container" style={{ position: 'relative' }}>
        <img
          ref={imageRef}
          src={pageData[pageNumber].image}
          useMap="#pageMap"
          onLoad={() => setImageLoaded(true)}
          alt={`Page ${pageNumber}`}
          style={{ userSelect: 'none' }}
        />

        {/* 高亮区域 */}
        {activeArea && (
          <div
            className="highlight-area"
            style={{
              position: 'absolute',
              backgroundColor: 'rgba(255, 217, 0, 0.2)',
              border: '2px solid rgba(243, 180, 11, 0.5)',
              borderRadius: '12px',
              pointerEvents: 'none',
              transition: 'all 0.3s ease',
              boxShadow: '0 0 10px rgba(255, 186, 0, 0.3)',
              opacity: 1,
              left: activeArea.coords.split(',')[0] * scale + 'px',
              top: activeArea.coords.split(',')[1] * scale + 'px',
              width: (activeArea.coords.split(',')[2] - activeArea.coords.split(',')[0]) * scale + 'px',
              height: (activeArea.coords.split(',')[3] - activeArea.coords.split(',')[1]) * scale + 'px',
            }}
          />
        )}

        {/* 动画手指图标 */}
        {showHand && (
          <div
            className="animated-hand"
            style={{
              position: 'absolute',
              width: '50px',
              height: '50px',
              backgroundImage: 'url(/shouzhi.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              pointerEvents: 'none',
              zIndex: 100,
              left: handPosition.x - 25 + 'px',
              top: handPosition.y - 8 + 'px',
            }}
          />
        )}

        {imageLoaded && (
          <map name="pageMap">
            {pageData[pageNumber].areas.map((area, index) => (
              <area
                key={index}
                shape="rect"
                coords={getScaledCoords(area.coords)}
                alt={area.title}
                title={area.title}
                onClick={(e) => handleAreaClick(area, e)}
                onTouchStart={(e) => {
                  e.preventDefault();
                  handleAreaClick(area, e);
                }}
                style={{cursor: 'pointer'}}
              />
            ))}
          </map>
        )}
      </div>
      <audio
        ref={audioRef}
        src={pageData[pageNumber].audio}
        preload="auto"
      />
    </div>
  );
};

export default BookPage;